<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Add a Fault</p>
        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getTopUps()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedTopup"
              ref="selectedTopup"
              :items="topups"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select a topup"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="errorAmount"
              ref="errorAmount"
              outlined
              dense
              label="Enter Error Amount"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-text-field
              v-model="errorName"
              ref="errorName"
              outlined dense
              label="Enter Error Name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-textarea
              outlined
              v-model="errorMessage"
              ref="errorMessage"
              name="input-7-4"
              label="Enter Error Message"
            ></v-textarea>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="errorFile"
              ref="errorFile"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Error File"
            ></v-file-input>
          </v-col>
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-checkbox label="Withdrawal" ref="errorWithdrawal" v-model="errorWithdrawal" class="me-3 mt-1"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="addFault()"
        >
          Add Fault
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {

    return {
      loading: false,
      userName: '',
      topups: [],
      selectedTopup: "",

      errorName: "",
      errorMessage: "",
      errorFile: [],
      errorAmount: "",
      errorWithdrawal: false,

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | Add Fault"
  },

  methods:{
    getTopUps(){
      axios
        .get('/api/v1/manager/get/topups/'+this.userName+'/')
        .then(response => {
          this.topups = response.data  // get the data and fill into campaigns
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async addFault(){
      // console.log(this.resourceFile)
      if(this.errorName === ""){
          this.$store.commit('setSnackBarMessage', "Please fill in the error name")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.errorName.focus()  // this causes a an auto fucos to the element
      }
      else if(this.errorMessage === ""){
          this.$store.commit('setSnackBarMessage', "Please input the error message")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.errorMessage.focus()  // this causes a an auto fucos to the element
      }
      else if(this.errorAmount === ""){
          this.$store.commit('setSnackBarMessage', "Please input the error amount")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.errorAmount.focus()  // this causes a an auto fucos to the element
      }
      else if(this.selectedTopup === ""){
          this.$store.commit('setSnackBarMessage', "Please select the topup")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.selectedTopup.focus()  // this causes a an auto fucos to the element
      }
      else if(!this.errorFile){
          this.$store.commit('setSnackBarMessage', "Please upload the error file")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.errorFile.focus()  // this causes a an auto fucos to the element
      }
      else {
          this.loading = true

          let formData = new FormData()
          formData.append('selectedTopup', this.selectedTopup)
          formData.append('errorName', this.errorName)
          formData.append('errorMessage', this.errorMessage)
          formData.append('errorAmount', this.errorAmount)
          // formData.append('selectedTopup', this.selectedTopup)
          formData.append('errorFile', this.errorFile)
          formData.append('errorWithdrawal', this.errorWithdrawal)

          await axios
              .post('/api/v1/manager/add/fault/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .then(response => {
                  this.$store.commit('setSnackBarMessage', response.data[0].text)
                  this.$store.commit('setSnackBarColor', response.data[0].color)
                  this.$store.commit('activateSnackBar', true)
                  if(response.data[0].id !== 0){
                      let router = this.$router
                      setTimeout(function(){
                          router.push({ name: 'savings.topup_faults'})
                      }, 4000)
                  }
              })
              .catch(error => {
                  if (error.response){
                      this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }else{
                      this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }
              })

          this.loading = false
      }
    },
  },
}
</script>
